import React, { useEffect, useRef, useState } from 'react';
import VideoPlayerControls from './VideoPlayerControls';

function VideoPlayer({ Video, width = '30vw' }) {
  const [isPaused, setIsPaused] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoProgress, setVideoProgress] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      setVideoDuration(video.duration);
    }
  }, []);

  useEffect(() => {
    if (isPaused) return;
    const currentTime = videoRef.current?.currentTime;

    if (videoDuration != null && currentTime != null) {
      let loadingTimeout = setTimeout(() => {
        if (videoProgress === currentTime / videoDuration) {
          setVideoProgress((prev) => prev + 0.000001);
        } else {
          if (!videoDuration) {
            setVideoDuration(videoRef.current?.duration);
          }
          setVideoProgress(currentTime / videoDuration);
        }
      }, 10);

      return () => {
        clearTimeout(loadingTimeout);
      };
    }
  }, [videoProgress, videoDuration, isPaused]);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      setIsPaused(!video.paused);
      video.paused ? video.play() : video.pause();
    }
  };

  return (
    <div
      className="relative mx-auto my-8 rounded-xl overflow-hidden"
      style={{
        width: width, // Set the width dynamically
        height: '0', // Start with 0 height
        paddingBottom: '100%', // Enforce square by padding equal to width
      }}
    >
      <div className="absolute top-4 right-4 z-10">
        <VideoPlayerControls
          progress={videoProgress}
          isPaused={isPaused}
          onPlayPause={togglePlayPause}
        />
      </div>
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-cover"
        loop
        autoPlay
        playsInline
      >
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoPlayer;
