import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useContentful } from './contentful-context';
import StoryText from './StoryText';

const images = [
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/maven-trusses.jpg'} className='h-[600px] mx-auto rounded-lg' />,
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/maven-wall.jpg'} className='h-[600px] mx-auto rounded-lg' />,
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/nelson-inside.jpg'} className='h-[600px] mx-auto rounded-lg' />,

]

function StoryTextStart({ title, subtitle, body,  }) {
  return (
      <div className='bg-white w-full text-start flex flex-col justify-center gap-3 sm:p-0'>
          {/* <Animate> */}
              {title && (<h1 className={`md:text-3xl text-xl font-semibold text-start sm:text-start`}>{title}</h1>)}
          {/* </Animate> */}
          {/* <Animate> */}
              {subtitle && (<p className={`md:text-3xl text-2xl text-[#a43424] md:text-center text-justify`}>{subtitle}</p>)}
          {/* </Animate> */}
          <h2
              className={`md:py-3 text-gray-600 text-justify ${title ? 'md:text-xl text-base' : 'md:text-4xl sm:text-2xl text-2xl'}`}
          >
              {body}
          </h2>
      </div>
  )
}

function TextImageBlock({ title, body }) {
  const [imageIndex, setImageIndex] = useState(0);
  const navigate = useNavigate();
  const content = useContentful();

  const handleClick = location => navigate(location);


  useEffect(() => {
    let imageTimeout = setTimeout(() => {
        nextImage();
    }, 3000)

    return () => {
        clearTimeout(imageTimeout);
    };
  })

  const openMatterport = () => {
    window.open('https://my.matterport.com/show/?m=ydhoJPRhwGE', '_blank');
  }

  if(!content.images.length){
    return null;
  }

  const nextImage = () => {
    const isFirstImage = imageIndex === 0;
    const nextImage = isFirstImage ? images.length -1 : imageIndex -1;
    setImageIndex(nextImage)
  }


  const slideImages = content.images.find(images => images.id == 'home-slider-images');
  const images = slideImages.images;
  const imageUrls = images.map((image, i) => image.fields.file.url);

  return (
    <div className="w-full bg-white">
      <div className="max-w-[1240px] mx-auto flex flex-col md:flex-row justify-between items-stretch p-4">
        {/* Story Section */}
        <div className="flex flex-col justify-startw-full">
          <StoryText title={title} body={body} />
          {/* Optional: Add a button for actions */}
          {/* <button
            onClick={openMatterport}
            className="bg-black text-white w-full md:w-auto rounded font-medium py-3 px-6 mt-4 hover:bg-gray-800 transition-all"
          >
            Tour Our Building System
          </button> */}
        </div>
        
        {/* Iframe Section */}
        <div className="w-full flex justify-center">
          <iframe
            className="w-full h-[300px] md:h-[440px] rounded-lg"
            src="https://my.matterport.com/show/?m=ydhoJPRhwGE"
            allowFullScreen
            allow="xr-spatial-tracking"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default TextImageBlock

// https://my.matterport.com/show/?m=ydhoJPRhwGE'