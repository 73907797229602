import React from 'react';
import Animate from './Animate';
import { useContentful } from './contentful-context';
import { getImageUrl } from './utils';

function MediaFeature({ title, body, url }) {
  return (
    <div className='w-full flex flex-col items-center'>
      <img src={url} className='py-4 h-[500px] w-[400px] hover:scale-105 transition ease-in-out duration-500 rounded-lg' />
      {/* <Animate> */}
        <h2 className='text-2xl font-semibold text-center text-black'>
          {title}
        </h2>
        <p className='p-2 sm:p-4 text-base md:text-lg text-justify text-black'>
          {body}
        </p>
      {/* </Animate> */}
    </div>
  )
}


function Features() {
  const content = useContentful();

  if(!content.mediaFeatures.length){
    return null;
  }

  return (
    <div className='w-full pt-[4rem] bg-white py-6'>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        {content.mediaFeatures.map(({ title, body, image }, i) => <MediaFeature title={title} body={body} url={image.fields.file.url} key={i} /> )}
      </div>
    </div>
  )
}

export default Features
