import React from "react";
import SquareVideoPlayer from './VideoPlayer/SquareVideoPlayer'

function TextMediaCard({ Video, title, body, button, animate }) {
    return (
      <div className="w-full bg-white py-10 px-4">
        <div className="max-w-[1240px] mx-auto flex flex-col items-center text-center">
            <h1 className="md:text-3xl text-xl font-semibold mb-6">{title}</h1>
        
            <SquareVideoPlayer Video={Video} width="100%" className="object-cover" />

            <p className="text-gray-600 py-6 text-base md:text-lg max-w-3xl text-justify">
                {body}
            </p>
            
            {button && (
                <button className="bg-black text-white w-[200px] rounded font-medium my-6 py-3">
                Learn about the system
                </button>
            )}
        </div>
      </div>
    );
  }
  
  export default TextMediaCard;
  