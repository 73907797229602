import React from 'react';
import TextBlock from '../TextBlock';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ComponentFeatures from '../ComponentFeatures';
import TextMediaBlock from '../TextMediaBlock';
import TextMediaBlockVariant from '../TextMediaBlockVariant';
import StoryText from '../StoryText';
import Animate from '../Animate';
import { useContentful } from '../contentful-context';
import { getImageUrlByName } from '../utils';
import AssemblyVideo from '../../assets/assembly-video.mp4';
// import RedwoodAssembly from '../../assets/redwood-assembly.mp4';
import RedwoodAssembly from '../../assets/redwood-assembly.mov';

import PileFoundationImage from '../../assets/maven-completed-wall.jpg';
import { Helmet } from "react-helmet";
import CompletedNelsonImage from '../../assets/completed-nelson.png';
import ConstruxModelImage from '../../assets/construx-modeling.png';
import { Link } from 'react-router-dom';
import TextMediaCard from '../TextMediaCard';

import { Carousel } from '../ComponentFeatures';

function Product() {
  const content = useContentful();

  if (!content.product.length) {
    return null;
  }

  const first = content.product.find(item => item.sectionId.indexOf('1') != -1);
  const second = content.product.find(item => item.sectionId.indexOf('2') != -1);
  const third = content.product.find(item => item.sectionId.indexOf('3') != -1);
  const fourth = content.product.find(item => item.sectionId.indexOf('4') != -1);
  const fifth = content.product.find(item => item.sectionId.indexOf('5') != -1);
  const sixth = content.product.find(item => item.sectionId.indexOf('6') != -1);

  const finishedFrameImageUrl = getImageUrlByName(content.images, 'frame-complete');
  const finishedNelsonImageUrl = getImageUrlByName(content.images, 'finished-nelson');
  const completedNelsonImageUrl = getImageUrlByName(content.images, 'finished-nelson-2');
  const frameOnPiles = getImageUrlByName(content.images, 'frame-on-piles');
  const frameOnSlab = getImageUrlByName(content.images, 'frame-on-slab-resized');


  return (
    <div className='bg-white px-10 md:px-20 font-bai'>
       <Helmet>
        <title>Product</title>
      </Helmet>
        <div className='flex flex-row'>
        <video className='w-full aspect-auto' loop autoPlay playsInline>
          <source src={RedwoodAssembly} type='video/mp4'></source>
        </video>
      </div>
        <StoryText
          title={first.header}
          body={first.section}
        />

<div className="w-full bg-white flex flex-col py-10">
  <h1 className="md:text-3xl sm:text-2xl text-xl font-semibold text-start md:text-center text-black p-10">
    Compatible with any foundation
  </h1>
  <div className="grid sm:grid-cols-1 md:grid-cols-2 text-center gap-8">
    <div className="flex flex-col items-center overflow-hidden">
      <img src={PileFoundationImage} className="w-full object-cover rounded-lg" />
      <p className="text-gray-600 py-6 text-base md:text-lg max-w-3xl text-center">
        Crawlspace or Pier & Beam
      </p>
    </div>
    <div className="flex flex-col items-center overflow-hidden">
      <img src={frameOnSlab} className="w-full object-cover rounded-lg" />
      <p className="text-gray-600 py-6 text-base md:text-lg max-w-3xl text-center">
        Slab or Basement
      </p>
    </div>
  </div>
</div>


      <ComponentFeatures />

      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4">
        <TextMediaCard
          title={second.header}
          body={second.section}
          Video={second.video.fields.file.url}
          button={false}
          animate
        />
        <TextMediaCard
          title={third.header}
          body={third.section}
          Video={third.video.fields.file.url}
          button={false}
          animate
        />
        <TextMediaCard
          title={fourth.header}
          body={fourth.section}
          Video={fourth.video.fields.file.url}
          button={false}
          animate
        />
        <TextMediaCard
          title={fifth.header}
          body={fifth.section}
          Video={fifth.video.fields.file.url}
          button={false}
          animate
        />
      </div>

      <Animate>
        <div className='w-full bg-white flex flex-row flex-wrap justify-around'>
          {/* <img src={sixth.imageUrl} /> */}
          <div className='flex flex-col justify-top py-10'>
            <h1 className='md:text-3xl sm:text-2xl text-xl font-semibold text-start md:text-center text-black'>
              {sixth.header}
            </h1>
            <p className='text-gray-600 py-6 text-base md:text-lg text-justify'>
              {sixth.section}
            </p>
          </div>
        </div>
      </Animate>

      <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-8 bg-white flex flex-row flex-wrap justify-around pb-10'>
        <img src={finishedNelsonImageUrl} className='w-full mt-2 rounded-lg' />
        <img src={completedNelsonImageUrl} className='w-full mt-2 rounded-lg' />
      </div>


      {/*
        <Carousel />
      <Link to='/models'>
          <h1 className='p-20 hover:cursor-pointer underline sm:text-lg text-xl font-semibold text-center md:text-center text-black'>
            View more models
          </h1>
      </Link>
      */}

    </div>
  )
}

export default Product
